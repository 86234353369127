import gql from 'graphql-tag';

export default gql`
query getPersonalizedVariableSetItems($setId: Int!) {
  getPersonalizedVariableSetItems(
    object: { personalized_variable_set_id: $setId }
  ) {
    project_id
    folder_id
    name
    description
    slug_path
    group_id
    group_name
  }
}
`;
