import gql from 'graphql-tag';

export default gql`
mutation deletePersonalizedVariableValues($setId: Int!, $projectId: Int, $folderId: Int) {
 deletePersonalizedVariableSetItem(
    object: {
      personalized_variable_set_id: $setId
      folder_id: $folderId
      project_id: $projectId
    }
  ) {
    personalized_variable_set_id
    folder_id
    project_id
  }
}
`;
