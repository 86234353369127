<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0">{{ currentPersonalizationSet.name }}</h3>
        <b-dropdown
          v-if="hasWriteAccess"
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="showEditPersonalizationModal = true">
            {{ $t('dropdowns.edit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="showDeletePersonalizationModal = true">
            {{ $t('dropdowns.delete') }}
          </b-dropdown-item>
        </b-dropdown>
        <modal-edit-personalization-set
          v-if="showEditPersonalizationModal"
          :set-name="currentPersonalizationSet.name"
          :set-description="currentPersonalizationSet.description"
          @edit-personalization-set="editPersonalizationSet"
          @close-edit-personalization-set="showEditPersonalizationModal = false"
        />
        <modal-delete-personalization-set
          v-if="showDeletePersonalizationModal"
          @delete-personalization-set="deletePersonalizationSet"
          @close-delete-personalization-set="showDeletePersonalizationModal = false"
        />
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="currentPersonalizationSet.description">
      <b-col cols="12" offset-sm="1" sm="11">
        <p class="mb-0">{{ currentPersonalizationSet.description }}</p>
      </b-col>
    </b-row>
    <personalization-values
      v-if="setId"
      :setId="setId"
      :has-write-access="hasWriteAccess"
      :key="personalizationValuesUpdateKey"
      :schema="contactsSchema"
      @create-value-in-schema="createEntryInSchema"
      @delete-value="deleteValue"
    />
    <personalization-contacts
      :schema="contactsSchema"
      :setId="setId"
      :personalization-set="currentPersonalizationSet"
      :setType="setType"
      :has-write-access="hasWriteAccess"
      @reimport-contacts="reimportContacts"
      @update-personalization-value="deleteCache"
    />
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.teamDescription.items') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right">
          <modal-add-new-item
            v-if="hasWriteAccess"
            class="d-inline-block"
            :user-id="userId"
            :key="newItemAddedKey"
            :items-from-d-b="items"
            @addItems="addItems"
          ></modal-add-new-item>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
          :fields="itemFields"
          :items="items"
          show-empty
          borderless
          dark
          class="table-view items-table"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #empty="scope">
            <p class="my-2">{{ $t('settings.personalization.table.emptyItems') }}</p>
          </template>
          <template #cell(image)="data" class="test">
            <div
              v-if="data.item.type === 'folder'"
              class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
            >
              <b-icon
                icon="folder"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
            <div
              v-if="data.item.type === 'project'"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                icon="files"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
          </template>
          <template #cell(group)="data">
            <span :id="`tooltip-group-${data.item.id}`">
            {{ data.item.group_name | sliceStingPos(20) }}
            </span>
            <b-tooltip
              v-if="data.item.group_name.length > 20"
              :target="`tooltip-group-${data.item.id}`"
            >
              {{ data.item.group_name }}
            </b-tooltip>
          </template>
          <template #cell(path)="data">
            <span :id="`tooltip-path-${data.item.id}`">
            {{ data.item.path | sliceStingPos(25) }}
            </span>
            <b-tooltip
              v-if="data.item.path.length > 25"
              :target="`tooltip-path-${data.item.id}`"
            >
              {{ data.item.path }}
            </b-tooltip>
          </template>
          <template #cell(name)="data">
            <span :id="`tooltip-name-${data.item.id}`">
            {{ data.item.name | sliceStingPos(23) }}
            </span>
            <b-tooltip
              v-if="data.item.name.length > 23"
              :target="`tooltip-name-${data.item.id}`"
            >
              {{ data.item.name }}
            </b-tooltip>
          </template>
          <template #cell(dot)="data">
            <b-dropdown
              v-if="hasWriteAccess"
              class="context-menu"
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
              right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical"
                        aria-hidden="true" scale=".8"
                ></b-icon>
              </template>
              <b-dropdown-item
                @click="removeItem(data.item)"
              >
                {{ $t('modals.deleteTeamItem.dropdown') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11" v-if="!hasWriteAccess">
        <div class="info-box px-4 py-2 text-center">
          {{ $t('settings.personalization.notAllowedToEdit') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import DeleteField from '@/graphQlQueries/mutations/deleteFieldFromPersonalizedVariableSetSchema';
import DeletePersonalizedVariableSetItem from '@/graphQlQueries/mutations/deletePersonalizedVariableSetItem';
import InsertItemToPersonalization from '@/graphQlQueries/mutations/insertItemToPersonalization';
import UpdatePersonalizedVariableSet from '@/graphQlQueries/mutations/updatePersonalizedVariableSet';
import AccessCheckForPersonalizationSet from '@/graphQlQueries/queries/accessCheckForPersonalizationSet';
import GetPersonalizedVariableSetItems from '@/graphQlQueries/queries/getPersonalizedVariableSetItems';
import GetUrlsOfItems from '@/graphQlQueries/queries/getUrlsOfItems';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import { isEqual } from 'lodash';

export default {
  name: 'PersonalizationListView',
  components: {
    ModalDeletePersonalizationSet: () => import('@/components/modals/personalization/ModalDeletePersonalizationSet.vue'),
    ModalEditPersonalizationSet: () => import('@/components/modals/personalization/ModalEditPersonalizationSet.vue'),
    PersonalizationContacts: () => import('@/components/settings/personalization/PersonalizationContacts.vue'),
    PersonalizationValues: () => import('@/components/settings/personalization/PersonalizationValues.vue'),
    ModalAddNewItem: () => import('@/components/modals/personalization/ModalAddNewPersonalizationItem.vue'),
  },
  props: ['personalizationSet', 'userId'],
  data() {
    return {
      filter: '',
      newItemAddedKey: 0,
      setId: null,
      setType: null,
      showEditPersonalizationModal: false,
      showDeletePersonalizationModal: false,
      currentPersonalizationSet: null,
      checkAccessForPersonalizationSet: null,
      personalizationValuesUpdateKey: 0,
      itemFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('settings.personalization.table.name'),
          sortable: true,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'path',
          label: this.$t('teamManagement.table.path'),
          sortable: true,
          width: 'auto',
          tdClass: 'path_column',
        },
        {
          key: 'group',
          label: this.$t('teamManagement.table.group'),
          sortable: true,
          width: '150px',
          tdClass: 'group_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      items: [],
      entries: [],
      contactsSchema: [],
      urlsOfItemsToDelete: [],
    };
  },
  computed: {
    hasWriteAccess() {
      console.log('hasWriteAccess', this.checkAccessForPersonalizationSet);
      return this.checkAccessForPersonalizationSet;
    },
  },
  apollo: {
    itemsQuery: {
      query: GetPersonalizedVariableSetItems,
      variables() {
        return { setId: this.setId };
      },
      update(data) {
        const itemObjects = [];
        let itemId = 1;
        data.getPersonalizedVariableSetItems.forEach((item) => {
          itemObjects.push({
            id: itemId,
            label: item.name,
            name: item.name === 'root_folder' ? item.group_name : item.name,
            description: item.description,
            type: (item.folder_id) ? 'folder' : 'project',
            project_id: item.project_id,
            folder_id: item.folder_id,
            group_id: item.group_id,
            group_name: item.group_name,
            path: item.slug_path,
          });
          itemId++;
        });
        // sort first after group name, then after path
        itemObjects.sort((a, b) => {
          if (a.group_name > b.group_name) {
            return -1;
          }
          if (a.group_name < b.group_name) {
            return 1;
          }
          if (a.path < b.path) {
            return -1;
          }
          if (a.path > b.path) {
            return 1;
          }
          return 0;
        });
        this.items = itemObjects;
        this.newItemAddedKey += 1;
        return itemObjects;
      },
      skip() {
        return !this.setId;
      },
      fetchPolicy: 'network-only',
    },
    checkAccessForPersonalizationSet: {
      query: AccessCheckForPersonalizationSet,
      variables() {
        return { setId: this.setId };
      },
      update(data) {
        return data.checkAccessToPersonalizedVariableSet.accessStatus === 'OWNER'
          || data.checkAccessToPersonalizedVariableSet.accessStatus === 'WRITE';
      },
      skip() {
        return !this.setId;
      },
      fetchPolicy: 'network-only',
    },
    urlsOfItemsToDelete: {
      query: GetUrlsOfItems,
      variables() {
        const slugPaths = this.items.map((item) => item.path);
        return {
          slugPaths,
        };
      },
      update(data) {
        const itemsToDelete = [];
        this.items.forEach((item) => {
          const itemUrlObject = data.item_lookup.find(
            (urlObject) => urlObject.slug_path === item.path
              && urlObject.group_id === item.group_id,
          );
          if (itemUrlObject) {
            itemsToDelete.push(itemUrlObject);
          }
        });
        return itemsToDelete;
      },
      skip() {
        return this.items.length === 0;
      },
      fetchPolicy: 'network-only',
    },
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  created() {
    console.warn('PersonalizationListView created', this.personalizationSet, this.personalizationSet.personalized_variable_set_id);
    if (this.personalizationSet) {
      this.setId = this.personalizationSet.personalized_variable_set_id;
      this.setType = this.personalizationSet.set_type;
      this.contactsSchema = (this.personalizationSet.schema)
        ? this.personalizationSet.schema
        : [];
    }
    this.currentPersonalizationSet = this.personalizationSet;
    this.setId = this.personalizationSet.personalized_variable_set_id;
  },
  watch: {
    personalizationSet(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.currentPersonalizationSet = this.personalizationSet;
      }
    },
  },
  methods: {
    async deleteValue(valueId) {
      this.contactsSchema = this.contactsSchema.filter((e) => e.id !== valueId);
      await this.deleteCache();
      await this.$apollo.mutate({
        mutation: DeleteField,
        variables: {
          setId: this.setId,
          fieldId: valueId,
        },
      });
      await this.$apollo.queries.itemsQuery.refresh();
      this.$emit('update-schema-in-personalization-set');
    },
    async editPersonalizationSet(personalizationSet) {
      this.showEditPersonalizationModal = false;
      await this.deleteCache();
      this.$emit('edit-personalization-set', personalizationSet, this.setId, this.contactsSchema);
    },
    async deletePersonalizationSet() {
      this.showDeletePersonalizationModal = false;
      await this.deleteCache();
      this.$emit('delete-personalization-set', this.setId);
    },
    async deleteItem(item) {
      await this.deleteCache();
      this.items.splice(this.items.indexOf(item), 1);
    },
    reimportContacts(personalizationSet) {
      this.$emit('reimport-contacts', {
        ...personalizationSet,
        setId: this.setId,
      });
    },
    deleteCache() {
      return new Promise((resolve, reject) => {
        const promises = [];
        if (this.urlsOfItemsToDelete.length) {
          for (let i = 0; i < this.urlsOfItemsToDelete.length; i++) {
            promises.push(this.$apollo.mutate({
              mutation: DeleteCacheFetchMagazine,
              variables: {
                domain: this.urlsOfItemsToDelete[i].domain,
                slug_path: this.urlsOfItemsToDelete[i].slug_path,
              },
            }));
          }
        }

        Promise.allSettled(promises).then(() => {
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
          resolve('all cache items deleted');
        }).catch((e) => {
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
          reject(new Error(e));
        });
      });
    },
    async createEntryInSchema(entry) {
      try {
        this.contactsSchema.push(entry);
        await this.$apollo.mutate({
          mutation: UpdatePersonalizedVariableSet,
          variables: {
            obj: {
              schema: JSON.stringify(this.contactsSchema),
              personalized_variable_set_id: this.setId,
              name: this.currentPersonalizationSet.name,
              description: this.currentPersonalizationSet.description,
            },
          },
        });
        await this.deleteCache();
        this.$emit('update-schema-in-personalization-set');
      } catch (error) {
        console.error('createEntryInSchema', error);
      }
    },
    async addItems(itemsToInsert, itemsToDelete) {
      console.log('adding new items...');
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      await this.deleteCache();
      const addDeleteItemsPromises = [];
      if (itemsToInsert.length) {
        for (let i = 0; i < itemsToInsert.length; i++) {
          const { itemId } = itemsToInsert[i];
          const { type } = itemsToInsert[i];
          let insertObject = {
            personalized_variable_set_id: this.setId,
            folder_id: null,
            project_id: null,
          };

          switch (type) {
            case 'group':
              insertObject.folder_id = itemsToInsert[i].rootFolderId;
              break;
            case 'folder':
              insertObject.folder_id = itemId;
              break;
            case 'project':
              insertObject.project_id = itemId;
              break;
            default:
              insertObject = null;
              break;
          }
          if (insertObject !== null) {
            addDeleteItemsPromises.push(this.$apollo.mutate({
              mutation: InsertItemToPersonalization,
              variables: {
                object: insertObject,
              },
            }));
          }
        }
      }
      if (itemsToDelete.length) {
        for (let i = 0; i < itemsToDelete.length; i++) {
          const item = itemsToDelete[i];
          const variables = {
            setId: this.setId,
            folderId: null,
            projectId: null,
          };
          switch (item.type) {
            case 'group':
              variables.folderId = item.rootFolderId;
              break;
            case 'folder':
              variables.folderId = item.itemId;
              break;
            case 'project':
              variables.projectId = item.itemId;
              break;
            default:
              break;
          }

          addDeleteItemsPromises.push(this.$apollo.mutate({
            mutation: DeletePersonalizedVariableSetItem,
            variables,
          }));
        }
      }
      if (addDeleteItemsPromises.length > 0) {
        Promise.allSettled(addDeleteItemsPromises).then(() => {
          this.$apollo.queries.itemsQuery.refresh();
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }).catch((e) => {
          console.log('error', e);
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        });
      }
    },
    async removeItem(item) {
      console.log('removing item...');
      try {
        const variables = {
          setId: this.setId,
          folderId: null,
          projectId: null,
        };
        switch (item.type) {
          case 'group':
          case 'folder':
            variables.folderId = item.folder_id;
            break;
          case 'project':
            variables.projectId = item.project_id;
            break;
          default:
            break;
        }
        await this.deleteCache();
        await this.$apollo.mutate({
          mutation: DeletePersonalizedVariableSetItem,
          variables,
        });
        await this.$apollo.queries.itemsQuery.refresh();
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/deep/ .table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        xdisplay: none;
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
/deep/ .align-items-center h3 {
  line-height: 34px;
}

/deep/ svg.bi-three-dots-vertical {
  top: -1px;
}
</style>
