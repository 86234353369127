import gql from 'graphql-tag';

export default gql`
query accessCheckForPersonalizationSet($setId: Int!) {
  checkAccessToPersonalizedVariableSet(
    object: { personalized_variable_set_id: $setId }
  ) {
    accessStatus
  }
}
`;
