var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.currentPersonalizationSet.name))
              ]),
              _vm.hasWriteAccess
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "context-menu",
                      attrs: {
                        "toggle-class": "dot-button",
                        "no-caret": "",
                        "no-flip": "",
                        right: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "three-dots-vertical",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2014744074
                      )
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.showEditPersonalizationModal = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("dropdowns.edit")) + " ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.showDeletePersonalizationModal = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("dropdowns.delete")) + " ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showEditPersonalizationModal
                ? _c("modal-edit-personalization-set", {
                    attrs: {
                      "set-name": _vm.currentPersonalizationSet.name,
                      "set-description":
                        _vm.currentPersonalizationSet.description
                    },
                    on: {
                      "edit-personalization-set": _vm.editPersonalizationSet,
                      "close-edit-personalization-set": function($event) {
                        _vm.showEditPersonalizationModal = false
                      }
                    }
                  })
                : _vm._e(),
              _vm.showDeletePersonalizationModal
                ? _c("modal-delete-personalization-set", {
                    on: {
                      "delete-personalization-set":
                        _vm.deletePersonalizationSet,
                      "close-delete-personalization-set": function($event) {
                        _vm.showDeletePersonalizationModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("hr")
          ])
        ],
        1
      ),
      _vm.currentPersonalizationSet.description
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm.currentPersonalizationSet.description))
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.setId
        ? _c("personalization-values", {
            key: _vm.personalizationValuesUpdateKey,
            attrs: {
              setId: _vm.setId,
              "has-write-access": _vm.hasWriteAccess,
              schema: _vm.contactsSchema
            },
            on: {
              "create-value-in-schema": _vm.createEntryInSchema,
              "delete-value": _vm.deleteValue
            }
          })
        : _vm._e(),
      _c("personalization-contacts", {
        attrs: {
          schema: _vm.contactsSchema,
          setId: _vm.setId,
          "personalization-set": _vm.currentPersonalizationSet,
          setType: _vm.setType,
          "has-write-access": _vm.hasWriteAccess
        },
        on: {
          "reimport-contacts": _vm.reimportContacts,
          "update-personalization-value": _vm.deleteCache
        }
      }),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.teamDescription.items")) + " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _vm.hasWriteAccess
                    ? _c("modal-add-new-item", {
                        key: _vm.newItemAddedKey,
                        staticClass: "d-inline-block",
                        attrs: {
                          "user-id": _vm.userId,
                          "items-from-d-b": _vm.items
                        },
                        on: { addItems: _vm.addItems }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c("b-table", {
                staticClass: "table-view items-table",
                attrs: {
                  fields: _vm.itemFields,
                  items: _vm.items,
                  "show-empty": "",
                  borderless: "",
                  dark: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          style: { width: field.width }
                        })
                      })
                    }
                  },
                  {
                    key: "empty",
                    fn: function(scope) {
                      return [
                        _c("p", { staticClass: "my-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "settings.personalization.table.emptyItems"
                              )
                            )
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "cell(image)",
                    fn: function(data) {
                      return [
                        data.item.type === "folder"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "folder",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type === "project"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "files",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(group)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-group-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(
                                    data.item.group_name,
                                    20
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.group_name.length > 20
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-group-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.group_name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(path)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-path-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(data.item.path, 25)
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.path.length > 25
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-path-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.path) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-name-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(data.item.name, 23)
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.name.length > 23
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-name-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(dot)",
                    fn: function(data) {
                      return [
                        _vm.hasWriteAccess
                          ? _c(
                              "b-dropdown",
                              {
                                staticClass: "context-menu",
                                attrs: {
                                  "toggle-class": "dot-button",
                                  "no-caret": "",
                                  dropup: "",
                                  "no-flip": "",
                                  right: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "three-dots-vertical",
                                              "aria-hidden": "true",
                                              scale: ".8"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.removeItem(data.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "modals.deleteTeamItem.dropdown"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          !_vm.hasWriteAccess
            ? _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
                [
                  _c("div", { staticClass: "info-box px-4 py-2 text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.personalization.notAllowedToEdit")
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }