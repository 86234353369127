import gql from 'graphql-tag';

export default gql`
mutation deleteCacheFetchMagazine($domain: String!, $slug_path: String) {
  deleteCacheFetchMagazine(
    object: {
      domain: $domain,
      slug_path: $slug_path
    }
  ) {
    domain
    slug_path
  }
}
`;
