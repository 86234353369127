import gql from 'graphql-tag';

export default gql`
query getUrlsOfItems($slugPaths: [String!]!) {
  item_lookup(where: {slug_path: {_in: $slugPaths}}) {
    group_id
    domain
    slug_path
  }
}
`;
