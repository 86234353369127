import gql from 'graphql-tag';

export default gql`
mutation deleteFieldFromPersonalizedVariableSetSchema($setId: Int!, $fieldId: String!) {
  deleteFieldFromPersonalizedVariableSetSchema(
    object: {
      field: $fieldId,
      personalized_variable_set_id: $setId
    }) {
    updatedPersonalizedVariableSetId
  }
}
`;
