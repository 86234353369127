import gql from 'graphql-tag';

export default gql`
  mutation insertItemToPersonalization($object: insertPersonalizedVariableSetItemInput!) {
    insertPersonalizedVariableSetItem(object: $object) {
      folder_id
      personalized_variable_set_id
      project_id
    }
  }
`;
